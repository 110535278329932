<template>
  <div v-disable-all="!can(uiPermissions.REPORTS_INGREDIENTS_VIEW)" class="container">
    <div class="page-header">
      <tabs :tab="routeName"></tabs>
    </div>
    <alert
      v-if="stockStored"
      type="success"
      message="Your stocks have been saved successfully."
      @close="stockStored = false"></alert>
    <alert
      v-if="invalid"
      type="warning"
      message="Some values are still missing or invalid. Please check them!"
      @close="invalid = false"></alert>
    <alert
      v-if="error"
      type="danger"
      message="Something went wrong; couldn't save your changes."
      @close="error = false"></alert>
    <div class="row">
      <div class="col justify-content-center align-items-center d-flex">
        <h2 class="mb-5">{{moment(stockDate).format('ddd, D MMM YYYY')}}</h2>
        <a
          href="#"
          class="mb-5 ml-2"
          @click.prevent="showChooseDateModal = true"><i class="fe fe-edit"></i>
        </a>
      </div>
    </div>
    <div v-if="StockStatus[currentWeekStock.status].status !== 'approved'" class="row row-deck">
      <div class="col-md-12 col-lg-3">
        <div class="card">
          <div class="card-body text-right">
            <form @submit.prevent="findArticle">
              <div class="form-group">
                <input
                  v-model="queryArticle"
                  type="text"
                  class="form-control"
                  placeholder="Search an article">
              </div>
              <button class="btn btn-primary">Search</button>
            </form>
          </div>
        </div>
      </div>
      <form
        ref="articleForm"
        novalidate
        class="validation bg-light col-md-12 col-lg-9"
        @submit.prevent="saveStocks">
        <div class="card">
          <div class="card-header">
            Selected {{selectedArticles.length}} out of {{articles.length}} articles
            <div class="card-options">
              <button
                v-if="articles.length && can(uiPermissions.REPORTS_INGREDIENTS_CREATE)"
                class="btn btn-primary"
                :disabled="!selectedArticles.length">
                Save
              </button>
            </div>
          </div>
          <div
            v-if="articles.length"
            class="article-form"
            style="overflow-y: auto; max-height: 400px;">
            <table class="table table-hover table-outline table-vcenter card-table sticky-table">
              <thead>
                <tr class="thead-light">
                  <th class="w-1 border-right-0 py-4 pl-4">
                    <input
                      type="checkbox"
                      class="align-middle"
                      @input="toggleAllCheckbox($event.target.checked)">
                  </th>
                  <th>Article</th>
                  <th class="text-center">Ordering uom</th>
                  <th class="text-center">Price</th>
                  <th class="text-center">Quantity</th>
                  <th class="text-center">Total price</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in articles"
                  :key="item.article_id"
                  :class="{'table-success': item.isSelected}">
                  <td class="border-right-0 pl-4">
                    <input
                      v-model="item.isSelected"
                      type="checkbox"
                      class="align-middle">
                  </td>
                  <td style="width: 25%">
                    <div>
                      <router-link v-if="can(uiPermissions.ARTICLES_VIEW)" :to="`/articles/${item.id}`">
                        {{item.name}}
                      </router-link>
                      <template v-else>
                        {{item.name}}
                      </template>
                      <div class="small text-muted">Brand: {{(item.brand || {}).name || 'N/A'}}</div>
                      <div class="small text-muted">Material: {{(item.material_type || {}).name || 'N/A'}}</div>
                    </div>
                  </td>
                  <td class="text-center">{{(item.ordering_uom || {}).name || 'N/A'}}</td>
                  <td class="text-center">{{numeral(item.preferred_replenishment.cost_price).format('0,0.00')}}</td>
                  <td class="text-center" style="width: 15%">
                    <input
                      v-model="item.selected.stock"
                      type="number"
                      class="form-control"
                      step="any"
                      :required="item.isSelected">
                  </td>
                  <td class="text-center">{{numeral(item.selected.stock * item.preferred_replenishment.cost_price).format('0,0.00')}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="card-body d-flex align-items-center justify-content-center">
            <h2>No articles found</h2>
          </div>
        </div>
      </form>
    </div>

    <div v-if="items.length" class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              Recorded stocks list - Status: <span :class="`text-${StockStatus[currentWeekStock.status].color}`">{{currentWeekStock.status}}</span>
            </div>
            <div class="card-options">
              <button
                v-if="StockStatus[currentWeekStock.status].status === 'pending'"
                class="btn btn-primary"
                @click.prevent="submitStocks">
                Submit for approval
              </button>
            </div>
          </div>
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-hover table-outline table-vcenter text-nowrap card-table">
                <thead>
                  <tr>
                    <th>Article</th>
                    <th class="text-center">Ordering uom</th>
                    <th class="text-center">Value</th>
                    <th class="text-center">Quantity</th>
                    <th class="text-center">Total value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.article_id">
                    <td style="width: 30%; white-space: normal;">
                      <div>
                        <router-link :to="`/articles/${item.article_id}`" target="_blank">{{(item.article || {}).name}}</router-link>
                        <div class="small text-muted">Brand: {{(item.article.brand || {}).name || 'N/A'}}</div>
                      </div>
                    </td>
                    <td class="text-center">{{(item.ordering_uom || {}).name || 'N/A'}}</td>
                    <td class="text-center">{{numeral((item.article || {}).price || 0).format('0,0.00')}}</td>
                    <td class="text-center">{{numeral(item.qty).format('0,0[.]00')}}</td>
                    <td class="text-center">{{numeral(item.value).format('0,0.00')}}</td>
                  </tr>
                  <tr v-if="items.length === 0">
                    <td colspan="8">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :currentPage="page"
              :totalPages="meta.totalPages"
              @goToPage="goToPage"></pagination>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showChooseDateModal"
      title="Choose a stock date"
      size="sm"
      id="modal-ingredients">
      <template slot="modal-header-close"><wbr/></template>

      <div class="form-group mb-0">
        <date-picker
          class="col"
          input-class="form-control"
          :lang="lang"
          format="ddd, D MMM YY"
          :shortcuts="[]"
          width="100%"
          :value="stockDate"
          @change="value => changeStockDate(value)">
        </date-picker>
      </div>
    </b-modal>
  </div>

</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {stocks} from '@/services';
import Tabs from '@/views/inventory/stocks/Tabs.vue';
import submitting from '@hellochef/shared-js/mixins/submitting';
import {BModal} from 'bootstrap-vue';
import numeral from 'numeral';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import StockStatus from '@/assets/enums/StockStatus';

export default {
  name: 'AddNewArticleStock',

  components: {

    BModal,
    DatePicker,
    Pagination,
    Tabs,
  },
  mixins: [

    list,
    submitting,
  ],
  props: {

    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

      StockStatus,
      articles: [],
      error: false,
      invalid: false,
      items: [],
      lang: {

        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      queryArticle: '',
      showChooseDateModal: false,
      stockDate: moment().startOf('week').toDate(),
      stockStored: false,
      weekStock: null,
    };
  },
  computed: {

    currentWeekStock() {
      return this.weekStock || {status: 'default'};
    },
    selectedArticles() {
      return this.articles.filter(item => item.isSelected);
    },
  },
  watch: {
    routeName() {
      this.refresh();

      // Reset the calendar selection when moving to different route.
      this.stockDate = moment().startOf('week').toDate();

      // Reset the search
      this.queryArticle = '';
      this.articles = [];
    },
  },
  methods: {

    changeStockDate(value) {
      this.items = [];
      this.articles = [];
      this.queryArticle = '';
      this.weekStock = null;

      this.stockDate = moment(value).toDate();

      this.refresh();
    },
    async fetchData(page) {
      this.items = [];
      this.weekStock = null;

      this.weekStock = (await stocks.getWeeklyStatus({
        params: {
          date: moment(this.stockDate).format('YYYY-MM-DD'),
          allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,
        },
      })).items[0];

      return stocks.getByParameters({

        column: 'ingredientsStock.id',
        end: moment(this.stockDate).format('YYYY-MM-DD'),
        limit: this.limit,
        page,
        start: moment(this.stockDate).format('YYYY-MM-DD'),
        with: 'orderingUom,article.brand',
        allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,
      });
    },
    async findArticle() {
      this.articles = [];

      const {items} = await stocks.getArticles({

        params: {

          date: moment(this.stockDate).format('YYYY-MM-DD'),
          query: this.queryArticle,
          with: 'wastage,brand,orderingUoms,materialType',
          allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,
        },
      });

      items.map(item => {
        return Object.assign(item, {

          isSelected: false,
          selected: {

            stock: item.stock?.qty || null,
            value: item.stock?.value || null,
          },
          value: 0,
        });
      });

      this.articles = items || [];
    },
    async saveStocks() {
      const payload = [];

      this.selectedArticles.forEach(item => {
        const format = {

          article_id: item.id,
          date: moment(this.stockDate).format('YYYY-MM-DD'),
          ordering_uom_id: item.ordering_uom?.id,
          qty: numeral(item.selected.stock).value(),
          reason: null,
          status: 'pending',
          value: numeral(item.selected.stock).value() * item.preferred_replenishment.cost_price,
        };

        payload.push(format);
      });

      this.invalid = false;
      this.error = false;
      this.submitted = false;

      // validate
      const form = this.$refs.articleForm;
      const valid = form.checkValidity();

      if (valid) {
        this.submitting = true;

        try {
          await stocks.saveOrUpdate(payload);

          this.stockStored = true;
          this.submitted = true;
        }
        catch (ex) {
          this.error = true;
          console.error(ex);
        }
        finally {
          this.submitting = false;
          this.queryArticle = '';
          this.articles = [];

          this.refresh();
        }
      }
      else { this.invalid = true; }

      form.classList.toggle('was-validated', this.invalid);
    },
    async submitStocks() {
      if (window.confirm('Are you sure? You cannot edit stocks for this day once it\'s sent for approval')) {
        this.error = false;
        this.submitted = false;

        this.submitting = true;

        try {
          await stocks.updateWeeklyStatus(
            {status: 'final'},
            {params: {week: moment(this.stockDate).format('YYYY-MM-DD')}});

          this.submitting = false;
          this.stockStored = true;
        }
        catch (ex) {
          this.error = true;
          console.error(ex);
        }
        finally {
          this.submitted = true;
          this.refresh();
        }
      }
    },
    toggleAllCheckbox(value) {
      this.articles.forEach(item => {
        item.isSelected = value;
      });
    },
  },
};

</script>

<style scoped>
.mx-datepicker {
    width: 100%;
}
</style>
